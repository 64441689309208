<template>
    <h3 style="text-align: left;">Maids</h3>
    <br>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-md-2">
                    <router-link to="/add_contractor" class="btn btn-outline-success btn-lg">Add Maid</router-link>
                </div>
            </div>
        </div>
        <div class="card-body">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th class="text-start" scope="col">Id</th>
                        <th class="text-start" scope="col">Fullname</th>
                        <th class="text-start" scope="col">Phone No</th>
                        <th class="text-start" scope="col">Category</th>
                        <th class="text-start" scope="col">Active</th>
                        <th class="text-start" scope="col">Options</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="contractor in Contractors" :key="contractor.id">
                        <!-- {{ contractor }} -->
                        <td class="text-start">{{ contractor.id }}</td>
                        <td class="text-start">{{ contractor.fullname }}</td>
                        <td class="text-start">{{ contractor.phone_no }}</td>
                        <td class="text-start">{{ contractor.category.charAt(0).toUpperCase() + contractor.category.slice(1) }}</td>
                        <td class="text-start"><input type="checkbox"  disabled class="form-check-input" v-model="contractor.active"></td>
                        <th class="text-start"><router-link :to="{path:'/edit_contractor', query:{id: contractor.id}}" class="btn btn-success">View</router-link> | <router-link :to="{path:'/maid_messages', query:{id: contractor.id}}" class="btn btn-success">Messages</router-link>  |<button type="button" class="btn btn-outline-danger"  @click="openModal(contractor)">
                            Delete</button></th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer">
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class="page-item">
                            <button class="page-link"  v-if="has_prev==true" @click="getPrev">Previous</button>
                            <span class="page-link" v-if="has_prev==false">Previous</span>
                        </li>
                        <li class="page-item"><span class="page-link">{{current_page}}</span></li>
                        <li class="page-item">
                            <button type="button" class="page-link" v-if="has_next==true" @click="getNext">Next</button>
                            <span class="page-link" v-if="has_next==false">Next</span>
                        </li>
                    </ul>
                  </nav>
            </div>
    </div>
    

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Delete</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
      </div>
      <div class="modal-body">
        Do you want to delete {{ current_contractor.fullname }}'s account
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">Close</button>
        <button type="button" class="btn btn-danger" @click="delete_contractor">Delete</button>
      </div>
    </div>
  </div>
</div>

</template>
<script>
import { mapGetters, mapActions } from 'vuex';
// eslint-disable-next-line
import { Modal } from 'bootstrap';

export default{
    data(){
        return {
            current_page: 1,
            has_prev: false,
            has_next: false,
            delete_modal: null,
            current_contractor: {
                fullname:"",
                id:""
            }
        };
    },
    computed: {
        ...mapGetters({Contractors: "StateContractors"})
    },
    async mounted() {
        this.delete_modal = new Modal('#exampleModal', {})

        await this.GetContractors(this.current_page - 1);
        if (this.valid_next())
            this.has_next = true
    },
    methods: {
        ...mapActions(["GetContractors", "DeleteContractor"]),

        valid_next()
        {
            return this.Contractors.length >= 20;
        },
        async getNext()
        {
            if(this.valid_next())
            {
                console.log("Getting next")
                await this.GetContractors(this.current_page)
                if (this.Contractors.length == 0)
                {
                    await this.GetContractors(this.current_page - 1)
                    this.has_next = false
                }
                else 
                    this.current_page += 1;
                if (this.current_page > 1)
                {
                    this.has_prev = true
                }
            }
        },

        async getPrev()
        {
            if (this.current_page > 1)
            {
                this.current_page -= 1;
                await this.GetContractors(this.current_page - 1)
                this.has_next = true
                
            }
            if (this.current_page == 1)
            {
                this.has_prev = false
            }
            
        },

        openModal(contractor)
        {
            this.current_contractor = contractor
            this.delete_modal.show()
        },

        closeModal()
        {
            this.delete_modal.hide();
        },

        async delete_contractor()
        {
            this.delete_modal.hide();
            this.DeleteContractor(this.current_contractor.id)

        }

    }
}
</script>