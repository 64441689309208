import Vuex from "vuex";
// import createPersistedState from 'vuex-persistedstate';
import auth from "./modules/auth";
import users from "./modules/users"
import contractors from "./modules/contractors";
import messages from "./modules/messages"

const store = new Vuex.Store(
    {
        modules: {
            auth,
            users,
            contractors,
            messages,
            
        }
    }
)

export default store;