<template>
    <div class="text-start container">
        <div class="row">
            <div class="col-md-8 offset-md-2 mt-5">
                <h1>Privacy Policy</h1>
                <p class="text-left">
                At Louisa's Inspiration, we are committed to protecting your privacy. 
                This Privacy Policy explains how we collect, use, disclose, and safeguard 
                your information when you visit our website or use our services.
                </p>
                <br>
                <h2> 1. Information We Collect </h2>
                <p> 
                    We may collect personal information that you provide directly to us, such as:
                    <ul>
                        <li>- Name</li>
                        <li>- Email address</li>
                        <li>- Phone number</li>
                        <li>- Payment information</li>
                        <li>- Any other details you submit through our contact forms or service requests.</li>
                    </ul>
                    We may also collect non-personal information automatically through cookies, analytics tools, or other technologies.
                </p>
                <br>
                <h2>2. How We Use Your Information</h2>
                <p>
                    We use the information we collect in the following ways:
                    <ul>
                        <li>
                            - To provide and maintain our services
                        </li>
                        <li>- To process transactions and payments</li>
                        <li>- To communicate with you, including responding to inquiries</li>
                        <li>- To improve and personalize your experience</li>
                        <li>- To comply with legal obligations.</li>
                    </ul>
                </p>
                <h2>3. Sharing Your Information</h2>
                <p>
                    We do not sell, trade, or rent your personal information to third parties. We may share your information in the following scenarios:
                    <ol>
                        <li>- With service providers who assist in our business operations (e.g., payment processors)</li>
                        <li>- To comply with legal requests or to protect our rights and safety.</li>
                    </ol>
                </p>
                <h2>4. Cookies and Tracking Technologies</h2>
                <p>
                    Our website uses cookies to enhance user experience. You can control cookie preferences through your browser settings. Note that disabling cookies may affect functionality.
                </p>
                <br>
                <h2>5. Data Security</h2>
                <p>We take reasonable measures to protect your information from unauthorized access, alteration, or disclosure. However, no method of data transmission or storage is completely secure, and we cannot guarantee absolute security.</p>
                
                <br>
                <h2>6. Your Rights</h2>
                <p>Depending on your location, you may have rights under data protection laws, including the right to access, correct, or delete your personal data. To exercise these rights, please contact us at [your email address].
                </p>
                <br>
                <h2>7. Changes to This Privacy Policy</h2>
                <p>We reserve the right to update this policy at any time. We will notify you of any significant changes by updating the “Effective Date” at the top of this policy.</p>

                <br>
                <h2>8. Contact Us</h2>
                <p class="mb-5">If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
            </div>
        </div>
    </div>
</template>