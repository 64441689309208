const baseURL = window.location.origin == "http://localhost:8080" ? "http://localhost:8000/api/contractors/admin/" : window.location.origin + "/api/contractors/admin";

const state = {
    contractors: [],
}

const getters = {
    StateContractors: state => state.contractors
}

const actions = {

        async GetContractors({commit, rootGetters}, page=0)
        {   
            let response = await fetch(`${baseURL}?skip=${page}`, {method:'GET', headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"}})
            // let response = await fetch(`${baseURL}admin?skip=${page}`, {method:'GET', headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"}})
            if (response.status == 200)
            {
                let data = await response.json();
                console.log("=======================")
                console.log(data)
                console.log("=======================")
                await commit("setContractors", data)
                return true;
            }
            return false            
        },

        async AddContractor({rootGetters}, contractor)
        {
            contractor.availabilities = contractor.availabilities.join(",")
            const data = {
                headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"},
                body: JSON.stringify(contractor),
                method: "POST"
            }
            let response = await fetch(`${baseURL}/add_contractor`, data);
            if( response.status == 200)
            {
                return true;
            }
        },

        async EditContractor({rootGetters}, contractor)
        {
            contractor.availabilities = contractor.availabilities.join(",")
            const data = {
                headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"},
                body: JSON.stringify(contractor),
                method: "PUT"
            }
            let response = await fetch(`${baseURL}/update_contractor/`, data);
            if (response.status == 200)
            {
                return true;
            }
        },

        async GetContractor({ rootGetters },contractor_id)
        {
            let response = await fetch(`${baseURL}/get_contractor/${contractor_id}`,{method:'GET', headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"}});
            if (response.status == 200)
            {
                return await response.json();
            }
            return null;
        },
        
        async DeleteContractor({ dispatch, rootGetters },contractor_id)
        {
            let response = await fetch(`${baseURL}/delete/${contractor_id}`,{method:'DELETE', headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"}});
            if (response.status == 200)
            {
                await dispatch("GetContractors")
                return true;
            }
            return false;
        },
        

}

const mutations = {
    setContractors(state, contractors)
    {
        state.contractors = contractors
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}