<template>
    <div class="container p-5">
        <h1>Contact Us</h1>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6 col-sm-12">
                <div v-if="screen" class="card p-5">
                    <h3>Send a message</h3>
                    <form @submit.prevent="submit">
                        <!-- Email input -->
                        <div class="form-outline mb-4">
                            <input type="text"  class="form-control"  placeholder="Email Address"/>
                        </div>
                        <div class="form-outline mb-4">
                            <input type="email"  class="form-control"  placeholder="Email Address"/>
                        </div>

                        <!-- Password input -->
                        <div class="form-outline mb-4">
                            <textarea name="Message"  rows="5" class="form-control"></textarea>
                        </div>

                        <!-- Submit button -->
                        <button type="submit" class="btn btn-primary btn-block m-2">Send Message</button> 

                    </form>
                </div>
                <div v-if="screen == false" >
                    <p>Thank you, your message has been sent</p>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            screen: true
        }
    },

    methods: {
        async submit(){
            this.screen = false;
        }
    }
}
</script>