import { createRouter, createWebHashHistory } from "vue-router";
import AdminPage from "@/views/AdminPage.vue";
import UserPage from "@/views/Users/UserPage.vue";
import AddUserPage from "@/views/Users/AddUserPage.vue";
import EditUserPage from "@/views/Users/EditUserPage.vue";
import ContractorsPage from "@/views/Contractors/ContractorsPage.vue";
import AddContractorPage from "@/views/Contractors/AddContractorPage.vue";
import EditContractorPage from "@/views/Contractors/EditContractorPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import MaidMessages from "@/views/Messages/MaidMessages.vue";
import PrivacyPage from "@/views/PrivacyPage.vue";
import ContactPage from "@/views/ContactPage.vue";


const routes = [
    {
        name:"Home",
        psth:"/",
        component: AdminPage,

        children:[
            {
                name:"User",
                path:"/users",
                component: UserPage
            },
            {
                name: "AddUser",
                path:"/add_user",
                component: AddUserPage
            },
            {
                name: "EditUser",
                path:"/edit_user",
                component: EditUserPage
            },
            {
                name: "Contractors",
                path: "/contractors",
                component: ContractorsPage
            },
            {
                name: "AddContractor",
                path: "/add_contractor",
                component: AddContractorPage
            },
            {
                name: "EditContractor",
                path: "/edit_contractor",
                component: EditContractorPage
            },
            {
                name: "MaidMessages",
                path: "/maid_messages",
                component: MaidMessages
            }
        ]
    },
    {
        name:"Login",
        path: "/login",
        component: LoginPage
    },
    {
        name: "Privacy",
        path: "/privacy",
        component: PrivacyPage
    },
    {
        name: "Contact",
        path: "/contact",
        component: ContactPage
    }
]

const router = createRouter(
    {
        history: createWebHashHistory(),
        routes
    }
);

export default router;