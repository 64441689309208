<template>
    <h3 style="text-align: left;">Users</h3>
    <br>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-md-2">
                    <router-link to="/add_user" class="btn btn-outline-success btn-lg">Add User</router-link>
                </div>
            </div>
        </div>
        <div class="card-body">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th class="text-start" scope="col">Username</th>
                        <th class="text-start" scope="col">Email</th>
                        <th class="text-start" scope="col">Roles</th>
                        <th class="text-start" scope="col">Options</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in Users" :key="user.id">
                        <td class="text-start">{{ user.id }}</td>
                        <td class="text-start">{{ user.username }}</td>
                        <td class="text-start">{{ user.email }}</td>
                        <td class="text-start text-white"><span v-for="role in user.roles.split(',')" :key="role" :class="{'bg-success':role =='superadmin', 'bg-primary':role =='admin', 'bg-info':role =='user'}" style="padding: 0px 5px; border-radius: 5px;"> {{role  }}</span></td>
                        <th class="text-start"><router-link :to="{path:'/edit_user', query:{id: user.id}}" class="btn btn-success">View</router-link></th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer">
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class="page-item">
                            <button class="page-link"  v-if="has_prev==true" @click="getPrev">Previous</button>
                            <span class="page-link" v-if="has_prev==false">Previous</span>
                        </li>
                        <li class="page-item"><span class="page-link">{{current_page}}</span></li>
                        <li class="page-item">
                            <button type="button" class="page-link" v-if="has_next==true" @click="getNext">Next</button>
                            <span class="page-link" v-if="has_next==false">Next</span>
                        </li>
                    </ul>
                  </nav>
            </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default{
    data(){
        return {
            current_page: 1,
            has_prev: false,
            has_next: false
        };
    },
    computed: {
        ...mapGetters({Users: "StateUsers"})
    },
    async mounted() {
        await this.GetUsers(this.current_page - 1);
        if (this.valid_next())
            this.has_next = true
    },
    methods: {
        ...mapActions(["GetUsers"]),

        valid_next()
        {
            return this.Users.length >= 20;
        },
        async getNext()
        {
            if(this.valid_next())
            {
                console.log("Getting next")
                await this.GetUsers(this.current_page)
                if (this.Users.length == 0)
                {
                    await this.GetUsers(this.current_page - 1)
                    this.has_next = false
                }
                else 
                    this.current_page += 1;
                if (this.current_page > 1)
                {
                    this.has_prev = true
                }
            }
        },

        async getPrev()
        {
            if (this.current_page > 1)
            {
                this.current_page -= 1;
                await this.GetUsers(this.current_page - 1)
                this.has_next = true
                
            }
            if (this.current_page == 1)
            {
                this.has_prev = false
            }
            
        }

    }
}
</script>