const baseURL = window.location.origin == "http://localhost:8080" ? "http://localhost:8000/api/messages/" : window.location.origin + "/api/messages/";

const state = {
    current_maid_users: [],
    current_maid_user_messages: []

}

const getters = {
    StateMaidUsers: state => state.current_maid_users,
    StateMaidUserMessages: state => state.current_maid_user_messages,
}

const actions = {
    
    async GetMaidUsers({ commit, rootGetters}, contractor_id)
    {
        let response = await fetch(`${baseURL}contractor_users?contractor=${contractor_id}`,{method:'GET', headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"}});
        if (response.status == 200)
        {
            var data = await response.json()
            await commit("setMaidUsers", data)
            return true;
        }
        return false;
    },

    async GetMaidUserMessages({ commit, rootGetters}, {contractor_id, user_id})
    {
        let response = await fetch(`${baseURL}get_contractor_user_messages?contractor=${contractor_id}&user=${user_id}`,{method:'GET', headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"}});
        if (response.status == 200)
        {
            var data = await response.json()
            await commit("setMaidUserMessage", data)
            return true;
        }
        return false;
    },

    async SendMaidUserMessage({ dispatch, rootGetters}, message)
    {
        const data = {
            headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"},
            body: JSON.stringify(message),
            method: "POST"
        }
        let response = await fetch(`${baseURL}send_message`, data);
        if (response.status == 200)
        {
            const contractor_id = message.contractor_id;
            const user_id = message.user_id;
            await dispatch("GetMaidUserMessages", {contractor_id, user_id})
        }
    }
}

const mutations = {
    setMaidUsers(state, users)
    {
        state.current_maid_users = users
    },

    setMaidUserMessage(state, messages)
    {
        state.current_maid_user_messages = messages
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}