const baseURL = window.location.origin == "http://localhost:8080" ? "http://localhost:8000/api/users/" : window.location.origin + "/api/users/";

const state = {
    users: null,
}

const getters = {
    StateUsers: state => state.users
}

const actions = {

        async GetUsers({commit, rootGetters}, page)
        {   
            let response = await fetch(`${baseURL}admin?skip=${page}`, {method:'GET', headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"}})
            if (response.status == 200)
            {
                let data = await response.json();
                await commit("setUsers", data)
                return true;
            }
            return false            
        },

        async AddUser({rootGetters}, user)
        {
            const data = {
                headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"},
                body: JSON.stringify(user),
                method: "POST"
            }
            let response = await fetch(`${baseURL}admin/add_user`, data);
            if( response.status == 200)
            {
                return true;
            }
        },

        async EditUser({rootGetters}, user)
        {
            const data = {
                headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"},
                body: JSON.stringify(user),
                method: "PUT"
            }
            let response = await fetch(`${baseURL}admin/update_user/${user.id}`, data);
            if (response.status == 200)
            {
                return true;
            }
        },

        async GetUser({ rootGetters },user_id)
        {
            let response = await fetch(`${baseURL}admin/get_user/${user_id}`,{method:'GET', headers: {"Authorization": rootGetters["StateToken"], "content-type":"application/json; charset=UTF-8"}});
            if (response.status == 200)
            {
                return await response.json();
            }
            return null;
        }

}

const mutations = {
    setUsers(state, users)
    {
        state.users = users
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}