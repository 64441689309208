<template>
    <h3 style="text-align: left;">Add Maid</h3>
    <b></b>
    <div class="row">
        <div class="col-md-10 col-sm-12">
            <div class="card">
                <div class="card-body">
                    <form style="text-align: left;" >
                        <div class="row" v-if="step==1">
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Fullname</label>
                                <input type="text" class="form-control" placeholder="Full Name"  v-model="form.fullname">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Gender</label>
                                <select class="form-control" v-model="form.gender">
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Date of Birth</label>
                                <input type="text" class="form-control" v-model="form.dob" placeholder="2024-01-01">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Category</label>
                                <select class="form-control" v-model="form.catogory">
                                    <option>Maid</option>
                                </select>
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Phone No</label>
                                <input type="text" class="form-control" placeholder="Phone no"  v-model="form.phone_no">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Address</label>
                                <input type="address" class="form-control" placeholder="Enter address"  v-model="form.address">
                                <!-- <small id="addressHelp" class="form-text text-muted">We'll never share your address with anyone else.</small> -->
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Nationality</label>
                                <input type="text" class="form-control" placeholder="Nationality" v-model="form.nationality">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label for="exampleInputlangauges_spoken1">Languages Spoken</label>
                                <input type="langauges_spoken" class="form-control" placeholder="English,Wollof"  v-model="form.langauges_spoken">
                            </div>
                        </div>
                        <!-- Second Stage -->
                        <div class="row" v-if="step==2">
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Right To Work</label>
                                <input type="text" class="form-control" placeholder="Passport/ID number"  v-model="form.right_to_work">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Background Check</label>
                                <input type="text" class="form-control" placeholder="Background Document" v-model="form.background_check">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Experience and Skills</label>
                                <textarea  class="form-control" cols="3" v-model="form.experience_and_skills" placeholder="Experience"> </textarea>
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>limitations</label>
                                <textarea type="text" class="form-control" cols="3" placeholder="Limitations"  v-model="form.limitations"> </textarea>
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Preferences</label>
                                <textarea type="text" class="form-control" cols="3" placeholder="Preferences"  v-model="form.preferences"></textarea>
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>References</label>
                                <textarea type="text" class="form-control" cols="3" placeholder="References"  v-model="form.references"></textarea>
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Health Conditions</label>
                                <textarea type="text" class="form-control" cols="3" placeholder="Health Conditions"  v-model="form.health_conditions"></textarea>
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Availabilities</label>
                                <select class="form-control" v-model="form.availabilities" multiple>
                                    <option>Monday</option>
                                    <option>Tuesday</option>
                                    <option>Wednesday</option>
                                    <option>Thursday</option>
                                    <option>Friday</option>
                                    <option>Saturday</option>
                                    <option>Sunday</option>
                                </select>
                            </div>
                        </div>

                        <!-- Third Stage  -->
                        <div class="row" v-if="step==3">
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Payment Method</label>
                                <input type="text" class="form-control" placeholder="Payment Methods"  v-model="form.payment_method">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Payment Details</label>
                                <textarea type="text" cols="3" class="form-control" v-model="form.payment_details" placeholder=""></textarea>
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Emergency Contact</label>
                                <input type="text" class="form-control" placeholder="Emergency Contact"  v-model="form.emergency_contact">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12 form-check form-switch">
                                <label class="form-check-label">Active</label>
                                
                                <input type="checkbox" class="form-check-input" v-model="form.active">
                            </div>
                        </div>
                        <br>
                        <div class="">
                            <button class="btn btn-outline-success mr-5" @click="previous">Previous</button> | <button class="btn btn-outline-success" @click="next">Next</button>
                        </div>
                        <br>
                        <button class="btn btn-primary btn-lg" @click="submit"> Save </button> | <router-link to="/contractors" class="btn btn-outline-success btn-lg">Back</router-link>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions} from "vuex";

export default {
    data() {
        return {
            step: 1,
            form: {
                address: "",
                fullname: "",
                dob: "2024-01-01",
                right_to_work: "",
                lastname: "",
                langauges_spoken: "",
                catogory: "Maid",
                background_check: "",
                experience_and_skills: "",
                availabilities: "",
                time_available: "",
                limitations: "",
                preferences: "",
                reference: "",
                health_conditions: "",
                payment_method: "",
                payment_details: "",
                emergency_contact: "",
                active: false
            }
        }
    },
    methods: {
        ...mapActions(["AddContractor"]),
        previous() {
            console.log("previous")
            if (this.step > 1)
                this.step -= 1
        },
        next(){
            console.log("Next")
            if (this.step < 3)
                this.step += 1
        },
        async submit() {
            this.AddContractor(this.form)
                .then(response => {
                    if (response == true)
                    {
                        this.$router.push("/contractors")
                    }
                })
        }
    },
}
</script>